import {
    InfrastructureDeviceRequest,
    InfrastructureDevices,
    InfrastructureDevicesRequest,
} from '@/types/infrastructure';
import { formatNumber } from '@/util/formatNumber';
import { isoDateToGermanDate } from '@/util/validateDateInput';

const mapInfrastructureDeviceRequest = <T extends InfrastructureDeviceRequest>(device: T) => ({
    ...device,
    commissioningDate: isoDateToGermanDate(device.commissioningDate),
    capacity: formatNumber(device.capacity),
});

export const transformInfrastructureDeviceRequest = (
    infrastructure: InfrastructureDevicesRequest,
): InfrastructureDevices => ({
    heatPumpDevices: infrastructure.heatPumpDevices.map(mapInfrastructureDeviceRequest),
    chargingInfrastructureDevices: infrastructure.chargingInfrastructureDevices.map(mapInfrastructureDeviceRequest),
    energyStorageDevices: infrastructure.energyStorageDevices.map(mapInfrastructureDeviceRequest),
    roomCoolingDevices: infrastructure.roomCoolingDevices.map(mapInfrastructureDeviceRequest),
});
