export const DASHBOARD = '/uebersicht';

export const APP = '/steuerbare-verbrauchseinrichtung';

// Routes for request form
export const REQUEST_FORM_ROOT = `${APP}/antragsstrecke`;

export const START = `${REQUEST_FORM_ROOT}/startseite`;
export const MAINTENANCE = `${REQUEST_FORM_ROOT}/wartung`;

export const NOT_RESPONSIBLE = `${REQUEST_FORM_ROOT}/zustaendigkeit`;
export const NO_CONTROLLABLE_CONSUMPTION_DEVICES_DETECTED = `${REQUEST_FORM_ROOT}/pruefung`;

export const DEVICE_DATA = `${REQUEST_FORM_ROOT}/steuerbare-verbrauchseinrichtung`;
export const DEVICE_DATA_LOCATION_DATA = `${DEVICE_DATA}/standortdaten`;
export const DEVICE_DATA_CONNECTION_PLAN = `${DEVICE_DATA}/plan`;
export const DEVICE_DATA_CONNECTION_VARIANT = `${DEVICE_DATA}/anschlussvariante`;
export const DEVICE_DATA_INFRASTRUCTURE = `${DEVICE_DATA}/infrastrukturen`;
export const DEVICE_DATA_GRID_FEES_REDUCTION = `${DEVICE_DATA}/netzentgeltreduzierung`;
export const DEVICE_DATA_INSTALLER = `${DEVICE_DATA}/installateur`;

export const ADDRESS = `${REQUEST_FORM_ROOT}/adresse`;
export const ADDRESS_PLANT_OPERATOR = `${ADDRESS}/anlagenbetreiber`;

export const REQUEST = `${REQUEST_FORM_ROOT}/antrag`;
export const REQUEST_OVERVIEW = `${REQUEST}/antragsuebersicht`;

export const NEXT_STEPS = `${REQUEST_FORM_ROOT}/naechste-schritte`;

// Routes for processes for finished registrations
export const FINISHED_REGISTRATION_ROOT = `${APP}/anmeldung/:registrationId`;

export const FINISHED_REGISTRATION_DETAILS = `${FINISHED_REGISTRATION_ROOT}/details`;
export const FINISHED_REGISTRATION_DOCUMENTS = `${FINISHED_REGISTRATION_ROOT}/dokumente`;
