import { useDownload } from '@/finished-registration/documents/hooks/useDownload';
import { DocumentMetadata } from '@/finished-registration/documents/types/DocumentMetadata';
import { ErrorBox, LoadingSpinner, StyledLink } from '@ten-netzkundenportal/ui-components';
import React, { useState } from 'react';

type DocumentViewLinkProps = {
    registrationId: string;
};

export const DocumentViewLink = ({
    fileName,
    documentId,
    type,
    documentType,
    registrationId,
}: DocumentMetadata & DocumentViewLinkProps): React.ReactElement => {
    const [showError, setShowError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const { download } = useDownload({
        fileName,
        documentId,
        type,
        documentType,
        registrationId,
    });

    const startDownload = () => {
        setIsLoading(true);
        setShowError(false);
        download()
            .catch((error) => {
                console.error('Failed to download document', error);
                setShowError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="w-full flex flex-row min-h-full flex-grow items-center">
            <StyledLink title={fileName} className="truncate py-2 break-all pr-4" onClick={startDownload}>
                {fileName}
            </StyledLink>
            {isLoading && (
                <div className="w-6 h-6">
                    <LoadingSpinner />
                </div>
            )}
            {showError && (
                <ErrorBox onClick={() => setShowError(false)} position="!absolute bottom-[50px] right-[10px]" />
            )}
        </div>
    );
};
