import { MarkerOwner } from '@/types/connectionData';
import { ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

export type ConnectionDataColumnProps = MarkerOwner;

export function ConnectionDataColumn({ plantOperatorIsConnectee, connecteeConsent }: ConnectionDataColumnProps) {
    return (
        <ColumnSection title="Anlagenplan">
            <ColumnItem label="Bestehender Anschluss" text="ja" />
            {plantOperatorIsConnectee === 'no' && connecteeConsent && (
                <ColumnItem label="Einverständnis des Anschlussnehmers" text="ja, liegt vor" />
            )}
        </ColumnSection>
    );
}
