import useApi from '@/hooks/useApi';
import { APIRequestState } from '@/types/api';
import { PersistedControllableConsumptionDevicesRegistration } from '@/types/controllableConsumptionDevicesRequest';
import { useEffect, useState } from 'react';

import { getControllableConsumptionDevicesRegistration } from '../api/getControllableConsumptionDevicesRegistration';

export const useControllableConsumptionDevicesRegistration = (registrationId: string) => {
    const [registration, setRegistration] = useState<PersistedControllableConsumptionDevicesRegistration | undefined>(
        undefined,
    );
    const [apiState, setApiState] = useState<APIRequestState>('initial');
    const controllableConsumptionDevicesApi = useApi(getControllableConsumptionDevicesRegistration);

    useEffect(() => {
        setApiState('loading');
        controllableConsumptionDevicesApi({ registrationId })
            .then((res) => {
                setApiState('finished_successfully');
                setRegistration(res);
            })
            .catch((error) => {
                console.error('Failed fetching registration!', error);
                setApiState('error');
            });
    }, [controllableConsumptionDevicesApi, registrationId]);

    return { registration, apiState };
};
