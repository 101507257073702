import { isChargingInfrastructureDevice } from '@/types/guards';
import { InfrastructureDevice } from '@/types/infrastructure';

import { parseNumberFromString } from './formatNumber';

const capacityLimit = 4.2;

export function isIndividualInfrastructureQualified(device: InfrastructureDevice): boolean {
    let isQualified = parseNumberFromString(device.capacity) > capacityLimit;

    if (isChargingInfrastructureDevice(device)) {
        isQualified = isQualified && device.accessType === 'noPublicAccess';
    }

    return isQualified;
}

export function isInfrastructureGroupQualified(devices: InfrastructureDevice[]) {
    const sumOfCapacity = devices.reduce((prev, device) => prev + parseNumberFromString(device.capacity), 0);

    return sumOfCapacity > capacityLimit;
}
