import { ChosenInstallerDataProperties } from '@/types/installerSelection';
import { ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

export type InstallerColumnProps = ChosenInstallerDataProperties;

export function InstallerColumn(installerColumnProps: InstallerColumnProps) {
    return (
        <ColumnSection title="Installateur">
            <ColumnItem label="Adresse" text={getInstallerAddressText(installerColumnProps)} />
        </ColumnSection>
    );
}

function getInstallerAddressText({ chosenInstaller }: ChosenInstallerDataProperties) {
    const installer = chosenInstaller;
    return [
        `${installer.companyNameDecoded}`,
        `${installer.address.street} ${installer.address.houseNumber}`,
        `${installer.address.postalCode} ${installer.address.city}`,
    ];
}
