import { Person } from '@/types/person';
import { PlantOperator } from '@/types/plantOperatorObject';
import { formatAcademicTitle } from '@/util/formatting';
import { CustomerType } from '@ten-netzkundenportal/ui-auth-utils';
import { CompanyName } from '@ten-netzkundenportal/ui-registration';

export function formatPlantOperatorPersonName(plantOperator: Person) {
    return `${formatAcademicTitle(plantOperator) || ''}${plantOperator.firstName} ${plantOperator.lastName}`;
}

export function formatPlantOperatorCompanyName(plantOperator: CompanyName) {
    return `${plantOperator.companyName}`;
}

export function getPlantOperatorName(plantOperator: PlantOperator, customerType: CustomerType) {
    if ((plantOperator.customerType === 'private' || customerType === 'private') && 'person' in plantOperator) {
        return formatPlantOperatorPersonName(plantOperator.person);
    }
    if ((plantOperator.customerType === 'company' || customerType === 'company') && 'company' in plantOperator) {
        return formatPlantOperatorCompanyName(plantOperator.company);
    }
    return '';
}
