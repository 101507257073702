export const formatNumber = (
    x: number | undefined,
    minimumFractionDigits: number = 1,
    maximumFractionDigits: number = 1,
): string => {
    if (x === undefined || x === null) {
        return '';
    }
    return x.toLocaleString('de', {
        minimumFractionDigits,
        maximumFractionDigits,
    });
};

export const parseNumberFromString = (input: string): number => {
    try {
        const formatted = input.replace(/\./g, '').replace(',', '.');
        const num = parseFloat(formatted);

        if (Number.isNaN(num)) {
            return 0;
        }

        return num;
    } catch (e) {
        return 0;
    }
};

export const formatStringNumber = (
    value: string,
    minimumFractionDigits: number = 1,
    maximumFractionDigits: number = 1,
) => formatNumber(parseNumberFromString(value), minimumFractionDigits, maximumFractionDigits);
