import { ConnectionVariant } from '@/types/connectionVariant';

export function connectionVariantToText(connectionVariant: ConnectionVariant) {
    switch (connectionVariant) {
        case 'sharedElectricityMeter':
            return `Gemeinsamer Zähler`;
        case 'separateElectricityMeter':
            return `Separate Zähler`;
        default:
            throw Error(`Unknown control type: ${connectionVariant}`);
    }
}
