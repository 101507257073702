import { Address } from '@/types/address';
import { isSaxonyAnhaltOrThuringiaCadastralDistrictLocation } from '@/types/guards';
import { isSaxony } from '@/types/locationData/guards';
import { Person } from '@/types/person';
import { Telephone } from '@/types/telephone';
import { AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search/build/types';
import { format } from 'date-fns';

export const formatDate = (dateInput: string | Date, dirtyFormatString = 'dd.MM.yyyy'): string => {
    let date: Date;
    if (typeof dateInput === 'string') {
        date = new Date(dateInput);
    } else {
        date = dateInput;
    }

    return format(date, dirtyFormatString);
};

export const formatAddress = (address: AddressLocation): [string, string] => [
    `${address.streetName} ${address.housenumber}${address.housenumberAddition ? address.housenumberAddition : ''}`,
    `${address.postcode} ${address.cityName}`,
];

export const formatPlantOperatorGermanAddress = (address: Address): string[] => {
    const addresses: string[] = [`${address.street} ${address.houseNumber}`];

    if (address.additionalAddressInformation) {
        addresses.push(address.additionalAddressInformation);
    }

    addresses.push(`${address.postalCode} ${address.city}`, 'Deutschland');
    return addresses;
};

export const formatCadastralDistrict = (district: CadastralDistrictLocation): string => {
    const { districtName, districtId, cadastralUnitCounter, cadastralUnitDenominator, stateCode } = district;

    let cadastralDistrictPart = '';
    if (!isSaxony(stateCode) && isSaxonyAnhaltOrThuringiaCadastralDistrictLocation(district)) {
        cadastralDistrictPart = `, ${+district.cadastralDistrict}`;
    }

    return (
        `${districtName} (${districtId})` +
        `${cadastralDistrictPart}` +
        `, ${+cadastralUnitCounter}` +
        `${+cadastralUnitDenominator ? `/${+cadastralUnitDenominator}` : ''}`
    );
};

export const formatTelephoneNumber = (telephone: Telephone): string =>
    `+${telephone.countryPrefix} ${telephone.number}`;

export const formatAcademicTitle = (person: Person) => {
    switch (person.academicTitle) {
        case 'Dr':
            return 'Dr. ';
        case 'Prof':
            return 'Prof. ';
        case 'ProfDr':
            return 'Prof. Dr. ';

        default:
            return undefined;
    }
};
