import { appConfig } from '@/app.config';
import { ApiParams } from '@/hooks/useApi';
import { PersistedControllableConsumptionDevicesRegistration } from '@/types/controllableConsumptionDevicesRequest';
import { AxiosResponse } from 'axios';

export const getControllableConsumptionDevicesRegistration = async ({
    customerId,
    axios,
    params,
}: ApiParams<{ registrationId: string }>): Promise<PersistedControllableConsumptionDevicesRegistration> => {
    const response: AxiosResponse<PersistedControllableConsumptionDevicesRegistration> = await axios.get(
        `${appConfig.services.controllableConsumptionDevicesApi}/account/${customerId}/controllable-consumption-devices-registration/${params.registrationId}`,
    );

    return response.data;
};
