import { useControllableConsumptionDevicesRegistration } from '@/finished-registration/hooks/useControllableConsumptionDevicesRegistration';
import useApi from '@/hooks/useApi';
import { DASHBOARD } from '@/routes';
import { APIRequestState } from '@/types/api';
import { Button, CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';

import { DocumentListArea } from './DocumentListArea';
import { getDocumentList } from './api/getDocumentList';
import { DocumentMetadata } from './types/DocumentMetadata';

export const FinishedRegistrationDocuments = () => {
    const { registrationId } = useParams<{ registrationId: string }>();

    const { registration, apiState: detailsApiState } = useControllableConsumptionDevicesRegistration(registrationId);

    const getDocumentListApi = useApi(getDocumentList);
    const [documentsApiState, setDocumentsApiState] = React.useState<APIRequestState>('initial');
    const [documents, setDocuments] = React.useState<DocumentMetadata[]>([]);

    useEffect(() => {
        setDocumentsApiState('loading');

        getDocumentListApi({ registrationId })
            .then((response) => {
                setDocuments(response);
                setDocumentsApiState('finished_successfully');
            })
            .catch((error) => {
                console.error('Failed to load documents', error);
                setDocumentsApiState('error');
            });
    }, [getDocumentListApi, registrationId]);

    if (
        documentsApiState === 'loading' ||
        documentsApiState === 'initial' ||
        detailsApiState === 'loading' ||
        detailsApiState === 'initial'
    ) {
        return <CenteredLoadingSpinner />;
    }

    const backButton = (
        <Button label="Zurück" type="secondary" id="goBack" className="mt-8" onClick={() => navigateToUrl(DASHBOARD)} />
    );

    if (documentsApiState === 'error' || detailsApiState === 'error') {
        return (
            <div className="mt-10 grid gap-y-5">
                <span>
                    Leider ist beim Laden der Dokumente ein technischer Fehler aufgetreten. Bitte versuchen Sie es
                    später erneut.
                </span>
                {backButton}
            </div>
        );
    }

    return (
        <div className="w-full flex flex-col gap-y-10 py-10">
            <h3 className="font-bold text-medium">Dokumentenverwaltung ({registration.processCommunicationId})</h3>
            {documents.length === 0 ? (
                <p>Derzeit sind keine Dokumente für Sie verfügbar.</p>
            ) : (
                <>
                    <p>
                        In diesem Bereich finden Sie alle vorliegenden Dokumente zu diesem Vorgang. Sie können diese
                        ansehen, indem Sie auf den Dokumentennamen klicken.
                    </p>
                    <DocumentListArea documents={documents} registrationId={registrationId} />
                </>
            )}
            {backButton}
        </div>
    );
};
