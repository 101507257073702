import { InfrastructureDevice, InfrastructureDevices } from '@/types/infrastructure';
import { formatNumber, parseNumberFromString } from '@/util/formatNumber';
import { isIndividualInfrastructureQualified, isInfrastructureGroupQualified } from '@/util/isInfrastructureQualified';
import { CheckThick, Close, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

const gridTemplateColumnsWithMeasurementLocationId = 'minmax(0, 0.2fr) auto 0.2fr 0.8fr 0.8fr minmax(0, 0.7fr)';
const gridTemplateColumnsWithoutMeasurementLocationId = 'minmax(0, 0.2fr) auto 0.15fr 0.8fr 0.8fr';

const hasDeviceWithMeasurementLocationId = (devices: InfrastructureDevice[]) =>
    devices.reduce((prev, device) => prev || !!device.measurementLocationId, false);

export type InfrastructureColumnProps = {
    infrastructure: InfrastructureDevices;
};

export function InfrastructureColumn({ infrastructure }: InfrastructureColumnProps) {
    const measurementLocationIdExists =
        hasDeviceWithMeasurementLocationId(infrastructure.roomCoolingDevices) ||
        hasDeviceWithMeasurementLocationId(infrastructure.heatPumpDevices) ||
        hasDeviceWithMeasurementLocationId(infrastructure.chargingInfrastructureDevices) ||
        hasDeviceWithMeasurementLocationId(infrastructure.energyStorageDevices);

    const gridTemplateColumns = measurementLocationIdExists
        ? gridTemplateColumnsWithMeasurementLocationId
        : gridTemplateColumnsWithoutMeasurementLocationId;

    return (
        <ColumnSection title="Infrastrukturen">
            <div className="text-gray-400 mb-4">
                Auf Basis Ihrer Angaben werden folgende Infrastrukturen als steuerbare Verbrauchseinrichtung nach §14a
                EnWG deklariert.
            </div>
            <div className="grid gap-2 text-center" style={{ gridTemplateColumns }}>
                <div />
                <div className="font-bold">Geräteart</div>
                <div />
                <div className="font-bold">Zählernummer</div>
                {measurementLocationIdExists && <div className="font-bold">Messlokationsnummer</div>}
                <div className="font-bold">Inbetriebsetzungsdatum</div>

                {infrastructure.heatPumpDevices?.length > 0 && (
                    <InfrastructureGroup
                        deviceName="Wärmepumpe"
                        infrastructureDevices={infrastructure.heatPumpDevices}
                        renderMeasurementLocationId={measurementLocationIdExists}
                    />
                )}

                {infrastructure.roomCoolingDevices?.length > 0 && (
                    <InfrastructureGroup
                        deviceName="Raumkühlung"
                        infrastructureDevices={infrastructure.roomCoolingDevices}
                        renderMeasurementLocationId={measurementLocationIdExists}
                    />
                )}

                {infrastructure.chargingInfrastructureDevices.map((chargingDevice, index) => (
                    <InfrastructureItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={`chargingInfrastructureDevices-${index}`}
                        checked={isIndividualInfrastructureQualified(chargingDevice)}
                        commissioningDate={chargingDevice.commissioningDate}
                        deviceName="Ladetechnik mit Leistung"
                        meterNumber={chargingDevice.electricityMeter}
                        capacity={parseNumberFromString(chargingDevice.capacity)}
                        measurementLocationId={chargingDevice.measurementLocationId}
                        renderMeasurementLocationId={measurementLocationIdExists}
                    />
                ))}

                {infrastructure.energyStorageDevices.map((storageDevice, index) => (
                    <InfrastructureItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={`energyStorageDevices-${index}`}
                        checked={isIndividualInfrastructureQualified(storageDevice)}
                        commissioningDate={storageDevice.commissioningDate}
                        deviceName="Energiespeicher mit Leistung"
                        meterNumber={storageDevice.electricityMeter}
                        capacity={parseNumberFromString(storageDevice.capacity)}
                        measurementLocationId={storageDevice.measurementLocationId}
                        renderMeasurementLocationId={measurementLocationIdExists}
                    />
                ))}
            </div>
        </ColumnSection>
    );
}

type InfrastructureGroupProps = {
    deviceName: string;
    infrastructureDevices: InfrastructureDevice[];
    renderMeasurementLocationId: boolean;
};

function InfrastructureGroup({
    deviceName,
    infrastructureDevices,
    renderMeasurementLocationId,
}: InfrastructureGroupProps) {
    const sumOfCapacity = infrastructureDevices.reduce(
        (prev, device) => prev + parseNumberFromString(device.capacity),
        0,
    );
    return (
        <>
            <InfrastructureItem
                checked={isInfrastructureGroupQualified(infrastructureDevices)}
                capacity={sumOfCapacity}
                deviceName={`${deviceName} mit Gesamtleistung`}
                commissioningDate="-"
                meterNumber="-"
                renderMeasurementLocationId={renderMeasurementLocationId}
            />
            {infrastructureDevices.map((chargingDevice, index) => (
                <InfrastructureItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`energyStorageDevices-${index}`}
                    commissioningDate={chargingDevice.commissioningDate}
                    deviceName={`${deviceName} mit Leistung`}
                    meterNumber={chargingDevice.electricityMeter}
                    capacity={parseNumberFromString(chargingDevice.capacity)}
                    measurementLocationId={chargingDevice.measurementLocationId}
                    renderMeasurementLocationId={renderMeasurementLocationId}
                    className={`${infrastructureDevices.length - 1 === index ? 'mb-2' : ''}`}
                />
            ))}
        </>
    );
}

type InfrastructureItemProps = {
    checked?: boolean;
    deviceName: string;
    capacity: number;
    meterNumber: string;
    measurementLocationId?: string;
    commissioningDate: string;
    renderMeasurementLocationId: boolean;
    className?: string;
};

function InfrastructureItem({
    checked,
    deviceName,
    capacity,
    meterNumber,
    measurementLocationId,
    commissioningDate,
    renderMeasurementLocationId,
    className,
}: InfrastructureItemProps) {
    return (
        <>
            {checked !== undefined && (
                <span>
                    {checked ? (
                        <CheckThick data-testid="check" className="h-5 w-5 mt-0.5 fill-current text-primary" />
                    ) : (
                        <Close data-testid="cross" className="h-5 w-5 mt-0.5 fill-current text-primary" />
                    )}
                </span>
            )}
            {checked === undefined && <span className="h-5 w-5" />}

            <span className={`${checked === undefined ? 'text-right' : 'text-left'} ${className}`}>{deviceName}</span>
            <span className="text-right">{formatNumber(capacity)} kW</span>

            <span className="text-center">{meterNumber}</span>
            {renderMeasurementLocationId && (
                <span title={measurementLocationId} className="text-center truncate">
                    {measurementLocationId || '-'}
                </span>
            )}
            <span className="text-center">{commissioningDate}</span>
        </>
    );
}
