import { MarkerOwner } from '@/types/connectionData';
import { ConnectionVariant } from '@/types/connectionVariant';
import { GridFeeReductionProperties } from '@/types/gridFeeReductionModule';
import { InfrastructureDevices } from '@/types/infrastructure';
import { ChosenInstallerDataProperties } from '@/types/installerSelection';
import { LocationType } from '@/types/locationData/locationData';
import { connectionVariantToText } from '@/util/connectionVariant';
import { gridFeeReductionToText } from '@/util/gridFeedReduction';
import { InfrastructureColumn } from '@components/overview-columns/ControllableConsumptionDevicesColumn/InfrastructureColumn';
import { InstallerColumn } from '@components/overview-columns/ControllableConsumptionDevicesColumn/InstallerColumn';
import { LocationColumn } from '@components/overview-columns/ControllableConsumptionDevicesColumn/LocationColumn';
import { Column, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { ConnectionDataColumn } from './ConnectionDataColumn';

export type ControllableConsumptionDevicesColumnProps = ChosenInstallerDataProperties &
    MarkerOwner & {
        infrastructure: InfrastructureDevices;
        location: LocationType;
        connectionVariant: ConnectionVariant;
        gridFeeReduction: GridFeeReductionProperties;
    };

export function ControllableConsumptionDevicesColumn({
    infrastructure,
    location,
    chosenInstaller,
    connectionVariant,
    gridFeeReduction,
    plantOperatorIsConnectee,
    connecteeConsent,
}: ControllableConsumptionDevicesColumnProps) {
    return (
        <div className="col-span-1">
            <Column title="Steuerbare Verbrauchseinrichtungen">
                <InfrastructureColumn infrastructure={infrastructure} />
                <div className="grid grid-cols-3 gap-x-2">
                    <LocationColumn location={location} />
                    <ConnectionDataColumn
                        plantOperatorIsConnectee={plantOperatorIsConnectee}
                        connecteeConsent={connecteeConsent}
                    />
                    <InstallerColumn chosenInstaller={chosenInstaller} />
                </div>
                <div className="grid grid-cols-2 gap-x-2">
                    <ColumnSection title="Netzentgeltreduzierung">
                        {gridFeeReductionToText(gridFeeReduction).map(([title, text]) => (
                            <div key={title}>
                                <div>{title}</div>
                                <div className="mt-1 whitespace-pre-line">{text}</div>
                            </div>
                        ))}
                    </ColumnSection>
                    <ColumnSection title="Anschlussvariante">
                        <ColumnItem text={connectionVariantToText(connectionVariant)} />
                    </ColumnSection>
                </div>
            </Column>
        </div>
    );
}
