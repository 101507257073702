import { formatDate } from '@/util/formatting';
import { isAfter, isBefore, isExists, startOfDay } from 'date-fns';

export type ValidateDateInputConfig = {
    minDate?: Date;
    maxDate?: Date;
    minDateErrorMessage?: string;
    maxDateErrorMessage?: string;
};

const germanDateRegex = /^(\d{2})\.(\d{2})\.(\d{4})$/;

export function validateDateInput(input: string, config?: ValidateDateInputConfig): string | undefined {
    if (!input) {
        return undefined;
    }

    const date = parseDate(input);
    if (!date) {
        return 'Bitte geben Sie ein Datum im Format tt.mm.jjjj ein.';
    }

    if (config?.minDate && isBefore(startOfDay(date), startOfDay(config.minDate))) {
        return config.minDateErrorMessage ?? `Bitte geben Sie ein Datum nach dem ${formatDate(config.minDate)} ein.`;
    }
    if (config?.maxDate && isAfter(startOfDay(date), startOfDay(config.maxDate))) {
        return config.maxDateErrorMessage ?? `Bitte geben Sie ein Datum vor dem ${formatDate(config.maxDate)} ein.`;
    }

    return undefined;
}

function parseDate(input: string): Date | undefined {
    try {
        if (germanDateRegex.test(input)) {
            const match = input.match(germanDateRegex);
            const day = Number(match[1]);
            const month = Number(match[2]) - 1; // Month index starts with 0 for january
            const year = Number(match[3]);

            if (isExists(year, month, day)) {
                return new Date(Date.UTC(year, month, day));
            }
        }
    } catch (e) {
        console.error(`Error during date validation ${input}`, e);
    }

    return undefined;
}

export function germanDateToIsoDate(input: string): string {
    if (input && germanDateRegex.test(input)) {
        return input.replace(germanDateRegex, '$3-$2-$1');
    }

    return undefined;
}

export function isoDateToGermanDate(input: string): string {
    const isoDateRegex = /^(\d{4})-(\d{2})-(\d{2})$/;

    if (input && isoDateRegex.test(input)) {
        return input.replace(isoDateRegex, '$3.$2.$1');
    }

    return undefined;
}
