import { appConfig } from '@/app.config';
import { ApiParams } from '@/hooks/useApi';

const serviceUrl = appConfig.services.controllableConsumptionDevicesApi;

export const getSas = async ({
    customerId,
    axios,
    params,
}: ApiParams<{
    registrationId: string;
}>) => {
    const response = await axios.get<{ sas: string; storageAccount: string }>(
        `${serviceUrl}/account/${customerId}/controllable-consumption-devices-registration/${params.registrationId}/sas`,
    );

    return response.data;
};
