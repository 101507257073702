// eslint-disable-next-line prefer-destructuring, @typescript-eslint/dot-notation
const env = window['env'];

export const appConfig = {
    tenantApplicationClientId: env.TENANT_APPLICATION_CLIENT_ID,
    tenantName: env.TENANT_NAME,
    userFlowName: 'B2C_1A_TEN_NKP_UNIVERSAL_SIGN_IN_V1',
    services: {
        installerApi: '/installer-api',
        accountApi: '/account-api',
        controllableConsumptionDevicesApi: '/controllable-consumption-devices-api',
        controllableConsumptionDevicesUserUploadApi: `${window.location.protocol}//${window.location.host}/controllable-consumption-devices-user-upload-api`,
        gisMiddlewareApi: '/gis-api',
        gisMiddlewareApiKey: env.CCD_APP_API_KEY_MIDDLEWARE_GIS,
    },
};

// The error is structured the following way containing the following ID used to react on the error
// ServerError: access_denied: AADB2C90118: The user has forgotten their password.
export const errorCodePasswordForgotButton = 'AADB2C90118';
