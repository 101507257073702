// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#single-spa-application\\:\\@ten-nkp\\/controllable-consumption-devices-app .form-layout {
    grid-template-columns: minmax(min-content, 35rem) minmax(min-content, 35rem);
}

@media (max-width: 1024px) {
    #single-spa-application\\:\\@ten-nkp\\/controllable-consumption-devices-app .form-layout {
        grid-template-columns: none;
    }
}

#single-spa-application\\:\\@ten-nkp\\/controllable-consumption-devices-app .full-main-container-width {
    margin-left: -3rem;
    margin-right: -3rem;
}`, "",{"version":3,"sources":["webpack://./src/base.css"],"names":[],"mappings":"AAAA;IACI,4EAA4E;AAChF;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".form-layout {\n    grid-template-columns: minmax(min-content, 35rem) minmax(min-content, 35rem);\n}\n\n@media (max-width: 1024px) {\n    .form-layout {\n        grid-template-columns: none;\n    }\n}\n\n.full-main-container-width {\n    margin-left: -3rem;\n    margin-right: -3rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
