import { appConfig } from '@/app.config';
import { DocumentMetadata } from '@/finished-registration/documents/types/DocumentMetadata';
import { ApiParams } from '@/hooks/useApi';
import { AxiosResponse } from 'axios';

type ListDocumentsParams = {
    registrationId: string;
};

export const getDocumentList = async ({
    params,
    axios,
    customerId,
}: ApiParams<ListDocumentsParams>): Promise<DocumentMetadata[]> => {
    const response: AxiosResponse = await axios.get(
        `${appConfig.services.controllableConsumptionDevicesApi}/account/${customerId}/controllable-consumption-devices-registration/${params.registrationId}/documents`,
    );

    return response.data;
};
