import { PlantOperator } from '@/types/plantOperatorObject';
import { formatPlantOperatorGermanAddress, formatTelephoneNumber } from '@/util/formatting';
import { CustomerType } from '@ten-netzkundenportal/ui-auth-utils';
import { Column, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { getPlantOperatorName } from './formatting';

export type PlantOperatorColumnProps = {
    plantOperator: PlantOperator;
    customerType: CustomerType;
};

export function PlantOperatorColumn({ plantOperator, customerType }: PlantOperatorColumnProps) {
    return (
        <div className="col-span-1">
            <Column title="Anlagenbetreiber">
                <ColumnSection title="Ihre Angaben">
                    <ColumnItem {...getPlantOperatorAddressText(plantOperator, customerType)} />
                    <ColumnItem label="Telefonnummer" text={getPlantOperatorTelephoneText(plantOperator)} />
                </ColumnSection>
            </Column>
        </div>
    );
}

function getPlantOperatorAddressText(plantOperator: PlantOperator, customerType: CustomerType) {
    return {
        label: 'Adresse',
        text: [
            getPlantOperatorName(plantOperator, customerType),
            ...formatPlantOperatorGermanAddress(plantOperator.address),
        ],
    };
}

function getPlantOperatorTelephoneText(plantOperator: PlantOperator) {
    return formatTelephoneNumber(plantOperator.contact.telephone);
}
