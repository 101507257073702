import { GridFeeReductionProperties } from '@/types/gridFeeReductionModule';

export const module1FlatRateNetworkChargeReductionName = 'Pauschale Netzentgeltreduzierung';
export const module1FlatRateNetworkChargeReductionText =
    'Dieses Modul sieht eine pauschale Reduzierung vor. Dabei gilt eine bundeseinheitliche Regelung zur Bestimmung dieser Pauschale. Die Pauschale wird einmal jährlich gewährt. Das Netzentgelt darf dabei nicht unter 0 € fallen.';

export const module2PercentageNetworkChargeReductionName = 'Prozentuale Netzentgeltreduzierung';
export const module2PercentageNetworkChargeReductionText =
    'Dieses Modul sieht eine prozentuale Reduzierung des Netzentgelt-Arbeitspreises um 60 % vor. Voraussetzung ist einerseits, dass der Verbrauch der steuerbaren Verbrauchseinrichtung separat gemessen wird (separater Zählpunkt). Andererseits muss für diese Messung eine separate Abrechnung (separate Marktlokation) erfolgen. \n\n' +
    'Modul 2 kann ausschließlich für Abrechnungen mit Standardlastprofil (Entnahme ohne Leistungsmessung) gewählt werden. ';

export const module3TimeVariableNetworkChargeName = 'Zeitvariables Netzentgelt';
export const module3TimeVariableNetworkChargeText =
    'Dieses Modul ist ab 01.01.2025 gültig und sieht eine Ergänzung zu Modul 1 um einen zeitlichen Faktor vor. Der Netzbetreiber legt unterschiedliche Preisstufen innerhalb eines Tages fest, die die typische Auslastung seines Netzes berücksichtigen.';

export function gridFeeReductionToText({
    module1_flatRateNetworkChargeReduction,
    module2_percentageNetworkChargeReduction,
    module3_timeVariableNetworkCharge,
}: GridFeeReductionProperties) {
    const text: string[][] = [];

    if (module1_flatRateNetworkChargeReduction) {
        text.push([`Modul 1: ${module1FlatRateNetworkChargeReductionName}`, module1FlatRateNetworkChargeReductionText]);
    }

    if (module2_percentageNetworkChargeReduction) {
        text.push([
            `Modul 2: ${module2PercentageNetworkChargeReductionName}`,
            module2PercentageNetworkChargeReductionText,
        ]);
    }

    if (module3_timeVariableNetworkCharge) {
        text.push([`Modul 3: ${module3TimeVariableNetworkChargeName}`, module3TimeVariableNetworkChargeText]);
    }

    return text;
}
