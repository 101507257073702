import { getOpenControllableConsumptionDevicesRegistration } from '@/api/getOpenControllableConsumptionDevicesRegistration';
import { checkAvailability } from '@/device/location-data/api/checkAvailability';
import useApi from '@/hooks/useApi';
import { DASHBOARD, START } from '@/routes';
import { APIRequestState } from '@/types/api';
import { getCustomerType } from '@/util/account';
import { mapLocationToGeoCoordinates } from '@/util/mapLocationToGeoCoordinates';
import { useLoggedInInstallerData } from '@/util/useLoggedInInstallerData';
import { SUBPROCESS } from '@/wizard/constants';
import { showNotResponsiblePage } from '@/wizard/guards';
import { useMsal } from '@azure/msal-react';
import { Button, CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';

import { contextMapper } from './contextMapper';

export const LandingPage = () => {
    const history = useHistory();
    const { accounts } = useMsal();
    const maxRetryCount = 3;
    const location = useLocation();

    const { processCommunicationId } = useParams<{ processCommunicationId: string }>();
    const { installerData } = useLoggedInInstallerData(accounts);
    const getOpenControllableConsumptionDevicesRegistrationApi = useApi(
        getOpenControllableConsumptionDevicesRegistration,
    );

    const [apiState, setApiState] = React.useState<APIRequestState | 'retrying'>('initial');
    const [retryCount, setRetryCount] = React.useState(0);

    const getOpenRegistrationAndFillContext = React.useCallback(async () => {
        if (accounts.length) {
            const openRegistration = await getOpenControllableConsumptionDevicesRegistrationApi({
                processCommunicationId,
            });

            const context = contextMapper(openRegistration, getCustomerType(accounts), installerData, location);
            const availability = await checkAvailability(mapLocationToGeoCoordinates(context.location));
            context.meta.locationAvailability = availability.electricity;

            if (showNotResponsiblePage({ context })) {
                context.meta.path = SUBPROCESS.NOT_RESPONSIBLE.path;
                context.meta.furthestProcessSubProcessIndex = [0, 0];
            }

            localStorage.setItem('steuerbare-verbrauchseinrichtung-antragsstrecke', JSON.stringify(context));
            history.push(`${START}?externer-antrag=true`);

            setApiState('finished_successfully');
        }
    }, [
        accounts,
        getOpenControllableConsumptionDevicesRegistrationApi,
        history,
        installerData,
        location,
        processCommunicationId,
    ]);

    React.useEffect(() => {
        if (apiState === 'initial') {
            setApiState('loading');
            getOpenRegistrationAndFillContext().catch(() => {
                setTimeout(() => {
                    setApiState('retrying');
                    setRetryCount((count) => count + 1);
                }, 2000);
            });
        }
    }, [getOpenRegistrationAndFillContext, apiState]);

    React.useEffect(() => {
        if (apiState === 'retrying') {
            setApiState('loading');
            getOpenRegistrationAndFillContext().catch((err) => {
                console.error(`Error fetching registration data for retry ${retryCount}`, err);
                if (retryCount >= maxRetryCount) {
                    setApiState('error');
                    return;
                }
                setTimeout(() => {
                    setApiState('retrying');
                    setRetryCount((count) => count + 1);
                }, 2000);
            });
        }
    }, [getOpenRegistrationAndFillContext, apiState, retryCount]);

    const backButton = (
        <Button
            label="Zur Übersicht"
            type="secondary"
            id="goBack"
            className="mt-8"
            onClick={() => navigateToUrl(DASHBOARD)}
        />
    );

    if (apiState === 'error') {
        return (
            <div className="mt-10 grid gap-y-5">
                <span>
                    Bitte versuchen Sie die Anmeldung Ihrer steuerbaren Verbrauchseinrichtung(en) später über den
                    entsprechenden Vorgang {processCommunicationId} auf Ihrem Dashboard erneut
                </span>
                {backButton}
            </div>
        );
    }

    return <CenteredLoadingSpinner />;
};
