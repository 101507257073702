import { FinishedRegistrationDetails } from '@/finished-registration/details/FinishedRegistrationDetails';
import { FinishedRegistrationDocuments } from '@/finished-registration/documents/FinishedRegistrationDocuments';
import { FINISHED_REGISTRATION_DETAILS, FINISHED_REGISTRATION_DOCUMENTS } from '@/routes';
import { AuthenticatedContent } from '@ten-netzkundenportal/ui-auth-utils';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

export const FinishedRegistrationRoot = () => (
    <AuthenticatedContent>
        <Switch>
            <Route path={FINISHED_REGISTRATION_DETAILS}>
                <FinishedRegistrationDetails />
            </Route>
            <Route path={FINISHED_REGISTRATION_DOCUMENTS}>
                <FinishedRegistrationDocuments />
            </Route>
        </Switch>
    </AuthenticatedContent>
);
