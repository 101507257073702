import { isAddressLocation } from '@/types/locationData/guards';
import { LocationType } from '@/types/locationData/locationData';
import { formatAddress, formatCadastralDistrict } from '@/util/formatting';
import { ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import { AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search/build/types';
import React from 'react';

export type LocationColumnProps = {
    location: LocationType;
};

export function LocationColumn({ location }: LocationColumnProps) {
    return (
        <ColumnSection title="Standort der steuerbaren Verbrauchseinrichtung(en)">
            <ColumnItem {...getPlantLocationText(location)} />
        </ColumnSection>
    );
}

function getPlantLocationText(location: LocationType) {
    const parseAddress = (address: AddressLocation) => ({
        label: 'Adresse',
        text: formatAddress(address),
    });

    const parseCadastralDistrict = (cadastralDistrict: CadastralDistrictLocation) => ({
        label: 'Flurstück',
        text: formatCadastralDistrict(cadastralDistrict),
    });

    return isAddressLocation(location) ? parseAddress(location) : parseCadastralDistrict(location);
}
