import { errorCodePasswordForgotButton } from '@/app.config';
import { DEVICE_DATA_LOCATION_DATA, MAINTENANCE, START } from '@/routes';
import { Hints } from '@/start/component/Hints';
import { isMaintenanceActive } from '@/util/maintenance';
import { AuthError } from '@azure/msal-browser';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Form, LoadingSpinnerButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';

const Start = (): React.ReactElement => {
    const { instance } = useMsal();
    const history = useHistory();

    const navigateToLoginPage = () => {
        const redirectKey = 'portal-app-local-redirect-url';
        sessionStorage.setItem(redirectKey, START);
        instance
            .loginRedirect()
            .then((res) => console.log(res))
            .catch((error) => {
                if ((error as AuthError).errorMessage?.includes(errorCodePasswordForgotButton)) {
                    navigateToUrl('/account/passwort-vergessen');
                }
            });
    };

    const navigateToRegistrationPage = () => {
        const redirectKey = 'nkp-post-signup-redirect-url';
        sessionStorage.setItem(redirectKey, START);
        navigateToUrl('/registrierung/');
    };

    const [startButtonLoading, setStartButtonLoading] = React.useState(false);

    const location = useLocation();
    const isExternalRequest = new URLSearchParams(location.search).get('externer-antrag') === 'true';
    let externalRequestStart: string | undefined;
    if (isExternalRequest) {
        const context = JSON.parse(localStorage.getItem('steuerbare-verbrauchseinrichtung-antragsstrecke'));
        externalRequestStart = context?.meta?.path;
    }

    const handleStartButtonClick = async () => {
        setStartButtonLoading(true);
        if (externalRequestStart === undefined) {
            localStorage.removeItem('steuerbare-verbrauchseinrichtung-antragsstrecke');
        }
        if (await isMaintenanceActive()) {
            history.push(MAINTENANCE);
        } else if (externalRequestStart !== undefined) {
            history.push(externalRequestStart);
        } else {
            history.push(DEVICE_DATA_LOCATION_DATA);
        }
        setStartButtonLoading(false);
    };

    return (
        <Form title="Steuerbare Verbrauchseinrichtung">
            <div id="explanation">
                <span>
                    Sie möchten einen steuerbaren Netzanschluss oder eine Verbrauchseinrichtung gemäß §14a des
                    Energiewirtschaftsgesetztes (EnWG) anmelden? Dann sind Sie hier genau richtig!
                </span>
            </div>

            <div className="flex flex-col gap-y-4">
                <span className="font-bold">Welche Informationen benötige ich für die Anmeldung?</span>
                <ul className="teagTickList ml-6 flex flex-col gap-y-4">
                    <li>
                        Eine oder mehrere für die steuerbare Verbrauchseinrichtung relevante{' '}
                        <span className="font-bold">Zählernummer(n)</span>
                    </li>
                    <li>
                        <span className="font-bold">Datum</span> der <span className="font-bold">Inbetriebsetzung</span>
                    </li>
                    <li>
                        Präferiertes <span className="font-bold">Modul</span> für die{' '}
                        <span className="font-bold">Netzentgeltreduzierung</span>
                    </li>
                    <li>Anschlussvariante der steuerbaren Verbrauchseinrichtung(en)</li>
                </ul>
            </div>

            <div className="flex flex-col gap-y-4">
                <span className="font-bold">Welche Verbrauchseinrichtungen fallen unter §14a EnWG?</span>
                Steuerbare Verbrauchseinrichtungen gemäß § 14a EnWG sind folgende Geräte mit einer elektrischen Leistung
                über 4,2 kW:
                <ul className="teagTickList ml-6 flex flex-col gap-y-4">
                    <li>Ladetechnik für Elektromobile ohne öffentlichen Zugang</li>
                    <li>Wärmepumpe (inkl. Zusatz - & Notheizung)</li>
                    <li>Raumkühlung</li>
                    <li>Energiespeicher (elektrische Energie)</li>
                </ul>
            </div>

            <UnauthenticatedTemplate>
                <div className="gap-y-4">
                    <div>
                        <span className="font-bold">Sind Sie schon im Netzkundenportal registriert?</span>
                    </div>
                    <div>
                        <br />
                        <span>
                            Für die Anmeldung der steuerbaren Verbrauchseinrichtungen und Netzanschlüssen gemäß §14a
                            EnWG benötigen Sie einen Account im Netzkundenportal. Sie besitzen bereits einen Account?
                            Melden sie sich bitte{' '}
                            <button className="text-primary" type="button" onClick={navigateToLoginPage}>
                                hier
                            </button>{' '}
                            an.
                        </span>
                        <br />
                        <span>
                            Sie sind noch nicht im Portal angemeldet? Kein Problem – eine schnelle{' '}
                            <button className="text-primary" type="button" onClick={navigateToRegistrationPage}>
                                Registrierung
                            </button>{' '}
                            ist jetzt oder innerhalb Ihres Antrags möglich, so dass Sie nach Abschluss Ihren Antrag
                            jederzeit einsehen können. Zudem können Sie im Portal Ihre Daten selbstständig anpassen, die
                            Bearbeitung verfolgen und Kontakt zu uns aufnehmen.
                        </span>
                    </div>
                </div>
            </UnauthenticatedTemplate>

            <div className="flex justify-center my-8">
                <LoadingSpinnerButton
                    label="Los geht's"
                    loading={startButtonLoading}
                    onClick={handleStartButtonClick}
                    timeout={0}
                />
            </div>

            <Hints />
        </Form>
    );
};

export default Start;
