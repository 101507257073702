import React from 'react';

import { DocumentViewLink } from './DocumentViewLink';
import { DocumentMetadata } from './types/DocumentMetadata';

type Props = {
    documents: DocumentMetadata[];
    registrationId: string;
};

export const fileCategoryMapper: Record<'misc', string> = {
    misc: 'Sonstiges',
};

export const DocumentListArea = ({ documents, registrationId }: Props) => {
    function createDocumentList() {
        return documents.map((document) => (
            <div className="flex w-full" key={document.documentId} data-testid="listed-documents">
                <div className="grid grid-cols-2 border-b border-dark-grey place-items-center gap-x-8 h-16 break-all w-full">
                    <DocumentViewLink registrationId={registrationId} {...document} />
                    <p title={fileCategoryMapper[document.documentType]} className="py-2 truncate w-full">
                        {fileCategoryMapper[document.documentType]}
                    </p>
                </div>
            </div>
        ));
    }

    return (
        <div className="h-96">
            <div className="overflow-y-scroll custom-scrollbar h-full p-2.5 mt-2 -ml-2.5 -mr-2.5">
                <div className="max-h-[36rem]">
                    <div className="flex w-full">
                        <div className="grid grid-cols-2 h-12 gap-x-8 border-b border-dark-grey w-full ">
                            <span className="font-bold flex pb-2">Name</span>
                            <span className="font-bold flex pb-2">Kategorie</span>
                        </div>
                    </div>
                    <div className="flex flex-col place-items-start h-full" data-testid="documentEntry">
                        {createDocumentList()}
                    </div>
                </div>
            </div>
        </div>
    );
};
