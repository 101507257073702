import { AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';
import { StateCode } from '@ten-netzkundenportal/ui-location-search/build/types/locationData';

export const isAddressLocation = (location: AddressLocation | CadastralDistrictLocation): location is AddressLocation =>
    (location as AddressLocation).cityName !== undefined;

export const isSaxony = (cadastralDistrictLocationOrStateCode: CadastralDistrictLocation | StateCode) => {
    if (!cadastralDistrictLocationOrStateCode) {
        return false;
    }

    if (typeof cadastralDistrictLocationOrStateCode === 'string') {
        return cadastralDistrictLocationOrStateCode === 'SN' || cadastralDistrictLocationOrStateCode === '14';
    }

    if (cadastralDistrictLocationOrStateCode.stateCode) {
        return (
            cadastralDistrictLocationOrStateCode.stateCode === 'SN' ||
            cadastralDistrictLocationOrStateCode.stateCode === '14'
        );
    }
    return false;
};
