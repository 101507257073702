import { ControllableConsumptionDevicesColumn } from '@/components/overview-columns/ControllableConsumptionDevicesColumn/ControllableConsumptionDevicesColumn';
import { PlantOperatorColumn } from '@/components/overview-columns/PlantOperatorColumn';
import { getPlantOperatorName } from '@/components/overview-columns/formatting';
import { DASHBOARD } from '@/routes';
import { Button, CenteredLoadingSpinner } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';

import { useControllableConsumptionDevicesRegistration } from '../hooks/useControllableConsumptionDevicesRegistration';
import { transformInfrastructureDeviceRequest } from './transformInfrastructureDeviceRequest';

export const FinishedRegistrationDetails = () => {
    const { registrationId } = useParams<{ registrationId: string }>();

    const { registration, apiState } = useControllableConsumptionDevicesRegistration(registrationId);

    const backButton = (
        <Button label="Zurück" type="secondary" id="goBack" className="mt-8" onClick={() => navigateToUrl(DASHBOARD)} />
    );

    if (apiState === 'error') {
        return (
            <div className="mt-10 grid gap-y-5" data-testid="error-get-registration">
                <span>
                    Die Details konnten leider nicht geladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt
                    erneut.
                </span>
                {backButton}
            </div>
        );
    }

    if (apiState === 'initial' || apiState === 'loading') {
        return <CenteredLoadingSpinner />;
    }

    return (
        <div className="w-full flex flex-col gap-y-10 py-10" data-testid="registration-details">
            <h3 className="font-bold text-medium">
                Anmeldung steuerbare Verbrauchseinrichtung -{' '}
                {getPlantOperatorName(registration.plantOperator, registration.customerType)} (
                {registration.processCommunicationId})
            </h3>
            <span>
                Nachfolgend sehen Sie die angegebenen Antragsdaten, die als Basis für die Anschlusszusage dienen. Wenn
                sich die Daten im Nachhinein geändert haben, wird Ihnen das hier nicht angezeigt.
            </span>
            <div className="flex flex-col gap-y-4">
                <div className="flex flex-col gap-y-4">
                    <ControllableConsumptionDevicesColumn
                        connectionVariant={registration.connectionVariant}
                        infrastructure={transformInfrastructureDeviceRequest(registration.infrastructure)}
                        location={registration.location}
                        chosenInstaller={{
                            ...registration.chosenInstaller,
                            companyNameDecoded: registration.chosenInstaller.companyName,
                        }}
                        gridFeeReduction={registration.gridFeeReductionModule}
                        plantOperatorIsConnectee={
                            registration.connectionDataProperties?.plantOperatorIsConnectee ? 'yes' : 'no'
                        }
                        connecteeConsent={registration.connectionDataProperties?.connecteeConsent}
                    />
                    <PlantOperatorColumn
                        plantOperator={registration.plantOperator}
                        customerType={registration.customerType}
                    />
                </div>
            </div>
            {backButton}
        </div>
    );
};
