import {
    SaxonyAnhaltOrThuringiaCadastralDistrictLocation,
    SaxonyCadastralDistrictLocation,
} from '@ten-netzkundenportal/ui-location-search/build/types';

import { ChargingInfrastructureDevice, InfrastructureDevice } from './infrastructure';

export const isSaxonyAnhaltOrThuringiaCadastralDistrictLocation = (
    location: SaxonyCadastralDistrictLocation | SaxonyAnhaltOrThuringiaCadastralDistrictLocation,
): location is SaxonyAnhaltOrThuringiaCadastralDistrictLocation =>
    (location as SaxonyAnhaltOrThuringiaCadastralDistrictLocation).cadastralDistrict !== undefined;

export const isChargingInfrastructureDevice = (device: InfrastructureDevice): device is ChargingInfrastructureDevice =>
    (device as ChargingInfrastructureDevice).accessType !== undefined;
